import * as React from "react"
import { PageProps, graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Box from "../components/box"

type DataProps = {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const NotFoundPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <Seo title="404: Not Found" />
      <Box
        css={{
          height: "75vh",
          width: "$full",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src="./apples.png"
          data-main-image=""
          style={{
            objectFit: "cover",
            filter: "invert(1)",
            mixBlendMode: "lighten",
            maxHeight: "240px",
            maxWidth: "240px",
          }}
          decoding="async"
          loading="lazy"
          alt="apples drawn without any hidden meaning"
        />
        <Box
          as="h1"
          css={{ marginBottom: "$0", fontSize: "72px", fontFamily: "$serif" }}
        >
          404
        </Box>
        <Box
          as="h3"
          css={{ marginBottom: "$32", marginTop: "0", fontFamily: "$serif" }}
        >
          page is not found...
        </Box>
        <p>
          let's <Link to="/">go home</Link>
        </p>
      </Box>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
